export const getErrorMsg = (error: any) => {
  let msg = "잠시 후 다시 시도해주세요.";
  if (error?.response?.data?.message) {
    msg = error.response.data.message;
  }
  return msg;
};

export const hasTouchSupport = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
};

export const arrayEquals = (a: any[], b: any[]) =>
  a.length === b.length && a.every((v, i) => v === b[i]);

/**
 * enter 감지 후 함수 실행
 * @param callback 실행시킬 함수
 */
export const onEnter =
  (callback: any) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      callback();
      e.currentTarget.blur();
    }
  };
