import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "src/store/global";
import { BackHistory } from "src/func/url";
import {
  MyInfoMemberTpl,
  MyInfoLoginTpl,
  MyInfoSocialConnectTpl,
  MyInfoLeaveTpl,
} from "./views";
import { useURI } from "ms_router";
import { ModalAlert, ModalConfirm, ModalLoader, Nav, spacing } from "ms-ui";
import { AuthStore } from "src/store/global/AuthStore";
import { MyInfoLoginStore } from "./store/MyInfoLoginStore";
import { FocusStore } from "./store/FocusStore";
import { MyInfoMemberStore } from "./store/MyInfoMemberStore";
import { reaction } from "mobx";

export interface IMyInfoProps {
  myInfoLoginStore: MyInfoLoginStore;
  myInfoMemberStore: MyInfoMemberStore;
  authStore: AuthStore;
  focusStore: FocusStore;
}

const MyInfo = observer(() => {
  const { navigate } = useURI();
  const { authStore } = useStores();
  const { user } = authStore;
  const [focusStore] = useState(() => new FocusStore());
  const [myInfoMemberStore] = useState(() => new MyInfoMemberStore(user!));
  const [myInfoLoginStore] = useState(() => new MyInfoLoginStore(user!));

  useEffect(() => {
    reaction(
      () => focusStore.focus,
      (curr, prev) => {
        if (curr !== prev) {
          if (prev === "nm" || prev === "nick" || prev === "orgEmail") {
            myInfoMemberStore.removeRequired([`${prev!}`]);
            myInfoMemberStore.setForm(
              prev,
              myInfoMemberStore.initialInfo[prev] ?? "",
              undefined
            );

            return;
          }

          if (prev === "phone" || prev === "pw") {
            return;
          }
        }
      }
    );
  }, []);

  return (
    <>
      <Nav
        title="내정보"
        left={{ type: "back", onClick: () => BackHistory(navigate, "/") }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: spacing[4].value,
          height: "calc(100dvh - 56px)",
        }}
      >
        {/* 회원 정보 */}
        <MyInfoMemberTpl
          authStore={authStore}
          myInfoLoginStore={myInfoLoginStore}
          focusStore={focusStore}
          myInfoMemberStore={myInfoMemberStore}
        />

        {/* 계정 정보 */}
        <MyInfoLoginTpl
          authStore={authStore}
          myInfoLoginStore={myInfoLoginStore}
          focusStore={focusStore}
          myInfoMemberStore={myInfoMemberStore}
        />

        {/* 소셜 연동 */}
        <MyInfoSocialConnectTpl
          authStore={authStore}
          myInfoLoginStore={myInfoLoginStore}
          focusStore={focusStore}
          myInfoMemberStore={myInfoMemberStore}
        />

        {/* 탈퇴 */}
        <MyInfoLeaveTpl />
      </div>

      <ModalLoader active={focusStore.isLoading} />

      <ModalAlert
        open={focusStore.alert.open}
        msg={focusStore.alert.msg}
        onClick={focusStore.alert.fn}
      />
      <ModalConfirm
        open={focusStore.confirm.open}
        msg={focusStore.confirm.msg}
        onCancel={() => {
          focusStore.setConfirm(false, "");
        }}
        onClick={focusStore.confirm.fn}
        confirmText="예"
        cancelText="아니오"
      />
    </>
  );
});

export default MyInfo;
