/**
 *   sms요청 후 시간계산
 * @param sec N초
 */
export default function MyTimer() {
  /* eslint-disable-next-line no-restricted-globals */
  const sctx: Worker = self as any;
  let timer: any;

  // 외부로 부터 메세지 수신
  onmessage = (e) => {
    if (e.data.on === true) {
      mtimer();
    } else {
      if (timer !== undefined) {
        clearInterval(timer);
      }
    }
  };

  function mtimer() {
    if (timer !== undefined) {
      clearInterval(timer);
    }

    const cdate = Math.floor(new Date().getTime() / 1000);

    timer = setInterval(() => {
      const distance = cdate + 120 - Math.floor(new Date().getTime() / 1000);

      if (distance <= 0) {
        clearInterval(timer);
        sctx.postMessage({
          text: "00:00",
          on: false,
        });
      } else {
        const minutes = Math.floor((distance % (60 * 60)) / 60);
        const seconds = Math.floor(distance % 60);

        let mm = minutes.toString();
        let ms = seconds.toString();
        if (minutes.toString().length === 1) {
          mm = "0" + minutes.toString();
        }

        if (seconds.toString().length === 1) {
          ms = "0" + seconds.toString();
        }

        sctx.postMessage({
          text: mm + ":" + ms,
          on: true,
        });
      }
    }, 1000);
  }
}
