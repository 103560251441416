import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Subtitle2, colors, Body3 } from "ms-ui";
import styled from "styled-components";

export interface IMyInfoSocialConnect {
  kakao: ISns;
  naver: ISns;
}

export interface ISns {
  /**
   * 소셜 계정 연동 여부
   */
  isConnected: boolean;
  onClickHandler: React.MouseEventHandler<HTMLDivElement>;
}

export const MyInfoSocialConnect = observer(
  ({ kakao, naver }: IMyInfoSocialConnect) => {
    return (
      <>
        <StDiv1>
          <StDiv2>소셜 계정 연결</StDiv2>
          <StDiv3>
            <StDiv4>
              {kakao.isConnected === true && (
                <StDiv5 onClick={kakao.onClickHandler}>
                  <img
                    src={`https://s3.ap-northeast-2.amazonaws.com/ms-file/${
                      process.env.REACT_APP_STAGE === "production"
                        ? "prod"
                        : "dev"
                    }/image/sns/kakaotalk.svg`}
                    width={`fit-content`}
                    height={`fit-content`}
                  />
                </StDiv5>
              )}
              {kakao.isConnected === false && (
                <StDiv6 onClick={kakao.onClickHandler}>
                  <KakaotalkDisabled />
                </StDiv6>
              )}
              {naver.isConnected === true && (
                <StDiv7 onClick={naver.onClickHandler}>
                  <img
                    src={`https://s3.ap-northeast-2.amazonaws.com/ms-file/${
                      process.env.REACT_APP_STAGE === "production"
                        ? "prod"
                        : "dev"
                    }/image/sns/naver.svg`}
                    width={`fit-content`}
                    height={`fit-content`}
                  />
                </StDiv7>
              )}
              {naver.isConnected === false && (
                <StDiv8 onClick={naver.onClickHandler}>
                  <img
                    src={`https://s3.ap-northeast-2.amazonaws.com/ms-file/${
                      process.env.REACT_APP_STAGE === "production"
                        ? "prod"
                        : "dev"
                    }/image/sns/naver.svg`}
                    width={`fit-content`}
                    height={`fit-content`}
                  />
                </StDiv8>
              )}
            </StDiv4>
            <StDiv9>아이콘을 눌러 소셜 계정을 연결해보세요.</StDiv9>
          </StDiv3>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[24].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${Subtitle2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[16].value};
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  gap: ${spacing[16].value};
  width: fit-content;
`;

const StDiv5 = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #fee500;
  cursor: pointer;
`;

const StDiv6 = styled.div`
  display: flex;
  padding: 14px 13.21px 13.647px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: ${colors.mainblue["050"].value};
  cursor: pointer;
`;

const StDiv7 = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #1ec800;
  cursor: pointer;
`;

const StDiv8 = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: ${colors.mainblue["050"].value};
  cursor: pointer;
`;

const StDiv9 = styled.div`
  ${Body3};
  color: ${contents.subtitle.value};
  width: fit-content;
`;

const KakaotalkDisabled = () => {
  return (
    <>
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9 0C4.88 0 0 3.92 0 8.76C0 11.9 2.07 14.66 5.17 16.21C4.94 17.07 4.35 19.34 4.22 19.83C4.07 20.43 4.44 20.42 4.68 20.26C4.87 20.13 7.67 18.2 8.87 17.36C9.54 17.46 10.22 17.51 10.89 17.51C16.91 17.51 21.79 13.59 21.79 8.76C21.79 3.93 16.92 0 10.9 0Z"
          fill="white"
        />
        <path
          d="M4.59201 7.40304H3.48201C3.34201 7.40304 3.20201 7.37304 3.09201 7.29304C2.98201 7.22304 2.91201 7.11304 2.89201 6.99304C2.88201 6.96304 2.88201 6.92304 2.87201 6.88304C2.87201 6.73304 2.93201 6.59304 3.05201 6.50304C3.17201 6.40304 3.33201 6.36304 3.49201 6.36304H6.88201C7.02201 6.36304 7.15201 6.39304 7.27201 6.46304C7.38201 6.53304 7.45201 6.63304 7.48201 6.76304C7.48201 6.80304 7.49201 6.84304 7.49201 6.87304C7.50201 7.02304 7.43201 7.16304 7.32201 7.25304C7.19201 7.35304 7.04201 7.40304 6.88201 7.39304H5.77201V11.213C5.78201 11.373 5.72201 11.533 5.61201 11.653C5.50201 11.773 5.34201 11.833 5.18201 11.823C5.05201 11.823 4.91201 11.783 4.80201 11.703C4.70201 11.623 4.62201 11.503 4.60201 11.373C4.60201 11.323 4.59201 11.273 4.60201 11.213L4.59201 7.40304Z"
          fill="#D2E4F9"
        />
        <path
          d="M7.77203 6.64301C7.81203 6.52301 7.88203 6.42301 7.98203 6.35301C8.09203 6.29301 8.21203 6.26301 8.34203 6.27301H8.62203C8.75203 6.27301 8.89203 6.30301 9.00203 6.36301C9.13203 6.45301 9.21203 6.57301 9.25203 6.72301L10.672 10.833C10.712 10.943 10.742 11.053 10.772 11.163C10.772 11.203 10.782 11.253 10.792 11.303C10.792 11.443 10.732 11.583 10.622 11.683C10.522 11.783 10.382 11.843 10.232 11.843C10.002 11.863 9.78203 11.723 9.70203 11.503L9.40203 10.603H7.51203L7.20203 11.503C7.13203 11.723 6.91203 11.863 6.68203 11.833C6.56203 11.833 6.43203 11.803 6.33203 11.723C6.24203 11.653 6.17203 11.543 6.15203 11.423C6.15203 11.383 6.14203 11.343 6.14203 11.303C6.14203 11.233 6.15203 11.153 6.17203 11.083C6.19203 11.003 6.22203 10.913 6.25203 10.833L7.77203 6.64301ZM8.48203 7.60301H8.46203L7.77203 9.75301H9.15203L8.48203 7.60301Z"
          fill="#D2E4F9"
        />
        <path
          d="M10.972 6.88305C10.962 6.72305 11.022 6.56305 11.132 6.44305C11.242 6.32305 11.402 6.26305 11.562 6.27305C11.702 6.27305 11.832 6.31305 11.942 6.39305C12.042 6.47305 12.112 6.59305 12.142 6.72305C12.152 6.77305 12.152 6.82305 12.152 6.87305V10.703H14.112C14.252 10.693 14.392 10.733 14.512 10.803C14.622 10.873 14.692 10.983 14.712 11.103C14.722 11.143 14.722 11.173 14.732 11.213C14.742 11.363 14.672 11.503 14.562 11.593C14.432 11.683 14.282 11.733 14.122 11.723H11.662C11.502 11.743 11.352 11.703 11.222 11.623C11.102 11.543 11.022 11.403 11.002 11.263C10.982 11.183 10.972 11.103 10.972 11.023V6.88305Z"
          fill="#D2E4F9"
        />
        <path
          d="M14.832 6.88307C14.822 6.72307 14.882 6.56307 14.992 6.44307C15.102 6.33307 15.262 6.27307 15.422 6.27307C15.562 6.27307 15.692 6.31307 15.802 6.39307C15.912 6.47307 15.982 6.59307 16.002 6.72307C16.012 6.77307 16.022 6.82307 16.012 6.87307V8.65307L17.852 6.53307C17.922 6.45307 17.992 6.39307 18.072 6.33307C18.152 6.29307 18.232 6.27307 18.322 6.27307C18.452 6.27307 18.572 6.31307 18.672 6.38307C18.772 6.44307 18.852 6.55307 18.872 6.67307C18.872 6.67307 18.872 6.69307 18.882 6.71307C18.882 6.73307 18.882 6.74307 18.882 6.76307C18.882 6.84307 18.862 6.91307 18.832 6.98307C18.792 7.05307 18.742 7.12307 18.692 7.18307L17.482 8.50307L18.912 10.7631L18.952 10.8331C19.022 10.9331 19.082 11.0531 19.122 11.1731C19.122 11.1831 19.122 11.1931 19.132 11.2131C19.132 11.2231 19.132 11.2431 19.132 11.2531C19.152 11.4231 19.082 11.5931 18.952 11.7031C18.832 11.7931 18.682 11.8431 18.522 11.8431C18.422 11.8431 18.322 11.8231 18.232 11.7831C18.142 11.7131 18.062 11.6331 18.002 11.5231L16.652 9.34307L16.012 10.0131L16.022 11.2331C16.032 11.3931 15.972 11.5531 15.862 11.6731C15.752 11.7831 15.592 11.8531 15.432 11.8431C15.292 11.8431 15.162 11.8031 15.052 11.7231C14.952 11.6431 14.872 11.5231 14.852 11.3931C14.842 11.3431 14.842 11.2931 14.842 11.2331L14.832 6.88307Z"
          fill="#D2E4F9"
        />
      </svg>
    </>
  );
};
