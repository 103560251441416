import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { MyInfoSocialConnect } from "./MyInfoSocialConnect";
import { MSHUTTLE_PATH } from "src/constants/addr";
import { get_slist } from "src/repository/Member/myinfo";
import { IMyInfoProps } from "../..";
import { getErrorMsg, ModalLoader } from "ms-ui";

export const MyInfoSocialConnectTpl = observer((props: IMyInfoProps) => {
  const { focusStore } = props;
  const [connectList, setConnectList] = useState<{ snsName: string }[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    loadConnectInfo();
  }, []);

  const socialConnect = (snsName: string) => {
    //passport 인증 끝점에 ajax 요청을 해서는 안된다. 전체 페이지를 해당 페이지로 리디렉션해야함
    let redirectURI = window.location.href;

    window.location.replace(
      `${
        MSHUTTLE_PATH.member_resource
      }/social/${snsName}?action=connect&redirect=${encodeURIComponent(
        redirectURI
      )}`
    );
  };

  /**
   * 소셜계정 연동 정보 로드
   */
  const loadConnectInfo = async () => {
    try {
      setLoading(true);
      const res = await get_slist().req();
      setConnectList(res.data.slist);
    } catch (e) {
      focusStore.setAlert({
        msg: getErrorMsg(e),
        open: true,
        fn: () => {
          focusStore.setAlert({ open: false });
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const socialFunc = (snsName: "kakao" | "naver") => {
    const isConnected =
      connectList.find((e) => e.snsName === snsName) !== undefined;

    const onClickHandler = () => {
      if (isConnected) {
        focusStore.setAlert({
          msg: "이미 연결된 소셜 계정입니다.",
          open: true,
          fn: () => {
            focusStore.setAlert({ open: false });
          },
        });
      } else {
        if (isLoading) return;
        socialConnect(snsName);
      }
    };

    return { isConnected, onClickHandler };
  };

  return (
    <>
      <ModalLoader active={isLoading} />

      <MyInfoSocialConnect
        kakao={socialFunc("kakao")}
        naver={socialFunc("naver")}
      />
    </>
  );
});
