import { useEffect, useState } from "react";
import { flowResult, reaction } from "mobx";
import WebWorker from "src/func/worker";
import MyTimer from "src/func/worker/timer";
import { IMyInfoLoginProps } from "..";
import { IPhoneProps } from "../MyInfoLogin";
import { isNil } from "ms_data";

export const PhoneProps = (props: IMyInfoLoginProps): IPhoneProps => {
  const { phoneStore, authFormStore, focusStore } = props;
  const { values, validation, timer, attributes, initialInfo } = phoneStore;

  const [webWorker] = useState(() => new WebWorker(MyTimer));

  useEffect(() => {
    if (webWorker) {
      webWorker.worker.onmessage = (e: any) => {
        const { on, text } = e.data as {
          on: boolean;
          text: string;
        };

        phoneStore.setTimer("text", text);
        if (text === "01:45") {
          phoneStore.setTimer("retry", true);
        }

        // 입력시간 초과
        if (!on) {
          phoneStore.setTimer("on", false);
          phoneStore.setTimer("text", "02:00");
          phoneStore.setTimer("retry", false);
          authFormStore.setForm("authNo", "", undefined);
        }
      };
    }
  }, [webWorker]);

  useEffect(() => {
    reaction(
      () => focusStore.focus,
      (curr, prev) => {
        if (prev === "phone" && curr !== "phone") {
          phoneStore.setForm("phone", "", undefined);
          phoneStore.setTimer("on", false);
          authFormStore.setForm("authNo", "", undefined);
        }
      }
    );
  }, []);

  /**
   * 인증번호 요청
   */
  const requestSMS = async () => {
    focusStore.setLoading(true);

    const { success, msg, authMsgId } = await flowResult(phoneStore.submit());

    if (success) {
      webWorker.worker.postMessage({ on: true });
      authFormStore.setForm("authMsgId", authMsgId);
    } else {
      focusStore.setAlert({
        msg,
        open: true,
        fn: () => {
          focusStore.setAlert({ open: false });
        },
      });
    }

    focusStore.setLoading(false);
  };

  return {
    isEdit: focusStore.focus === "phone",
    onEditHandler: () => focusStore.setFocus("phone"),
    initialValue: initialInfo.phone!,
    input: {
      ...attributes.phone,
      type: "tel",
      inputMode: "numeric",
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        phoneStore.setForm("phone", e.currentTarget.value),
      invalid:
        validation.phone.isValid === false ||
        values.phone === initialInfo.phone,
      value: values.phone || "",
      onBlur: (e: React.FocusEvent<HTMLInputElement>) =>
        phoneStore.setForm("phone", e.currentTarget.value),
    },
    inputMsg: {
      active: !validation.phone.isValid || values.phone === initialInfo.phone,
      msg:
        values.phone === initialInfo.phone
          ? "기존 휴대폰 번호와 동일합니다."
          : validation.phone.errorMessage,
    },
    onSubmitHandler: () => requestSMS(),
    isPossRequest:
      validation.phone.isValid === true &&
      !isNil(values.phone) &&
      (!timer.on || timer.retry) &&
      phoneStore.initialInfo.phone !== values.phone,
  };
};
