import { makeObservable } from "mobx";
import { AjvFormStore } from "ms-ui";
import { OasPutEditPassword } from "src/repository/Member/oas";

const customOas = {
  ...OasPutEditPassword,
  properties: {
    ...OasPutEditPassword.properties,
    newPwC: {
      type: "string" as const,
      title: "새 비밀번호 확인",
    },
  },
  required: [...OasPutEditPassword.required, "newPwC"] as const,
};

export class PwStore extends AjvFormStore<typeof customOas> {
  constructor() {
    super(customOas);
    makeObservable(this, {});
  }
}
