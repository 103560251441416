import { action, computed, makeObservable, observable, toJS } from "mobx";
import { CommonStore } from "ms-ui";

export class FocusStore extends CommonStore {
  _focus?: "nm" | "nick" | "orgEmail" | "phone" | "pw" | "bank";

  constructor() {
    super();
    makeObservable(this, {
      _focus: observable,
      focus: computed,
      setFocus: action,
    });
  }

  get focus() {
    return toJS(this._focus);
  }

  setFocus(f: typeof this.focus) {
    this._focus = f;
  }
}
