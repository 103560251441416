import { observer } from "mobx-react";
import { MyInfoLeave } from "./MyInfoLeave";
import { useState } from "react";
import LeaveStore from "./LeaveStore";
import { ModalAlert, ModalConfirm, ModalLoader } from "ms-ui";
import { flowResult } from "mobx";

export const MyInfoLeaveTpl = observer(() => {
  const [leaveStore] = useState(() => new LeaveStore());
  const { alert, confirm } = leaveStore;

  return (
    <>
      <ModalLoader active={leaveStore.isLoading} />

      <ModalAlert
        open={alert.open}
        msg={alert.msg}
        onClick={() => {
          alert.fn();
          leaveStore.setAlert({ ...alert, open: false, msg: "" });
        }}
      />

      <ModalConfirm
        open={confirm.open}
        msg={confirm.msg}
        onClick={async () => {
          await flowResult(confirm.fn());
          leaveStore.setConfirm(false, "");
        }}
        onCancel={() => leaveStore.setConfirm(false, "")}
        clickId={"wdrl_confirm"}
        cancelId={"wdrl_cancel"}
        confirmText="예"
        cancelText="아니오"
      />

      <MyInfoLeave
        onLeaveHandler={async () => {
          await flowResult(leaveStore.checkLeave());
        }}
      />
    </>
  );
});
