import { foundation, getErrorMsg } from "ms-ui";
import { isNil } from "ms_data";
import { edit_user_phone } from "src/repository/Member/edit";
import { IMyInfoLoginProps } from "..";
import { IAuthProps } from "../MyInfoLogin";

export const AuthProps = (props: IMyInfoLoginProps): IAuthProps => {
  const { authStore, phoneStore, focusStore, authFormStore } = props;
  const { values, validation, attributes } = authFormStore;

  /**
   * 인증번호 확인 및 폰번호 변경
   */
  const onSubmitHandler = async () => {
    try {
      focusStore.setLoading(true);

      const data = {
        phone: phoneStore.values.phone!,
        authNo: values.authNo!,
        authMsgId: values.authMsgId!,
      };

      const res = await edit_user_phone(data).req();
      const { success } = res.data;
      if (success) {
        phoneStore.setInitialInfo("phone", phoneStore.values.phone);
        authStore.setUser({
          ...authStore.user,
          phone: phoneStore.values.phone,
        });

        focusStore.setFocus(undefined);
        focusStore.setAlert({
          msg: "휴대폰번호가 변경되었습니다.",
          open: true,
          fn: () => focusStore.setAlert({ open: false }),
        });
      }
    } catch (e) {
      focusStore.setAlert({
        msg: getErrorMsg(e),
        open: true,
        fn: () => {
          focusStore.setAlert({ open: false });
        },
      });
    } finally {
      focusStore.setLoading(false);
    }
  };

  return {
    isVisible: !isNil(values.authMsgId) && phoneStore.timer.on,
    timer: (
      <div style={{ color: foundation.normal.primary.value }}>
        {phoneStore.timer.text}
      </div>
    ),
    isPossConfirm:
      phoneStore.isValid &&
      phoneStore.values.phone !== phoneStore.initialInfo.phone &&
      validation.authNo.isValid !== false &&
      !isNil(values.authNo) &&
      !focusStore.isLoading,
    onSubmitHandler,
    input: {
      ...attributes.authNo,
      value: values.authNo || "",
      invalid: validation.authNo.isValid === false,
      type: "text",
      inputMode: "numeric" as React.HTMLAttributes<"input">["inputMode"],
      onChange: (e) => authFormStore.setForm("authNo", e.currentTarget.value),
      onBlur: (e) => authFormStore.setForm("authNo", e.currentTarget.value),
    },
    inputMsg: {
      active: validation.authNo.isValid === false,
      msg: validation.authNo.errorMessage,
    },
  };
};
