/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ReqPutEditInfo  } from './interface/req/put/Req.Put.EditInfo';
import { CommonForm  } from './interface/common/form/Common.Form';
import { ReqPutEditPhone  } from './interface/req/put/Req.Put.EditPhone';
import { ReqPutEditPassword  } from './interface/req/put/Req.Put.EditPassword';
import { ReqPutEditPassword2  } from './interface/req/put/Req.Put.EditPassword2';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Edit {

/**
 * 닉네임, 이름, 이메일 수정
*/
edit_user_info(data: ReqPutEditInfo){
 return PUT<CommonForm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/edit`,
 }, data);
}
/**
 * 핸드폰 번호 변경
*/
edit_user_phone(data: ReqPutEditPhone){
 return PUT<CommonForm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/edit/phone`,
 }, data);
}
/**
 * 로그인 상태로 비밀번호를 변경
*/
edit_pw(data: ReqPutEditPassword){
 return PUT<CommonForm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/edit/password`,
 }, data);
}
/**
 * 비로그인 상태로 비밀번호를 변경
*/
edit_pw2(data: ReqPutEditPassword2){
 return PUT<CommonForm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/edit/password2`,
 }, data);
}
 
}

export const EditRepository = new Edit();
export const { edit_user_info, edit_user_phone, edit_pw, edit_pw2 } = EditRepository;
	