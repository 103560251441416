import { AxiosResponse } from "axios";
import { flow, makeObservable } from "mobx";
import { CommonStore } from "ms-ui";
import { getErrorMsg } from "src/func/utils";
import { get_remove_check, remove_user } from "src/repository/Member/remove";

class LeaveStore extends CommonStore {
  constructor() {
    super();
    makeObservable(this, {
      checkLeave: flow,
      leave: flow,
    });
  }

  *checkLeave() {
    this._loading = true;
    const { req } = get_remove_check();
    try {
      const { data: canLeave }: AxiosResponse<boolean> = yield req();
      console.log("canLeave: ", canLeave);
      if (canLeave) {
        this._confirm = {
          open: true,
          msg: "앗! 정말 탈퇴하시겠습니까?",
          fn: () => this.leave(),
        };
      } else {
        this._alert = {
          open: true,
          msg: "현재 탑승중 혹은 탑승예정인 \n 출근길이 있습니다. 탈퇴를 원하시면 \n 1:1문의로 연락주세요.",
          fn: () => {},
        };
      }
    } catch (error) {
      this._alert = { open: true, msg: getErrorMsg(error), fn: () => {} };
    } finally {
      this._loading = false;
    }
  }

  *leave() {
    this._loading = true;
    const { req } = remove_user();
    try {
      const { data }: AxiosResponse<{ success: boolean }> = yield req();
      if (data.success === true) {
        window.location.replace("/comebackPlz");
      }
    } catch (error) {
      this._alert = { open: true, msg: getErrorMsg(error), fn: () => {} };
    } finally {
      this._loading = false;
    }
  }
}

export default LeaveStore;
