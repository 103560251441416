import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, pointerEvt, foundation, Body2 } from "ms-ui";
import styled from "styled-components";

export interface IMyInfoLeave {
  onLeaveHandler: React.MouseEventHandler<HTMLDivElement>;
}

export const MyInfoLeave = observer(({ onLeaveHandler }: IMyInfoLeave) => {
  return (
    <>
      <StDiv1>
        <StDiv2 data-id={`wdrl_check`} onClick={onLeaveHandler}>
          회원탈퇴하기
        </StDiv2>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value} 0px ${spacing[40].value} 0px;
  justify-content: center;
  background: ${contents.secondary.value};
  height: 100%;
`;

const StDiv2 = styled.div`
  ${(p) => pointerEvt(p["data-id"])};
  ${Body2};
  color: ${foundation.normal.primary.value};
  text-align: center;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
`;
