import { isNil } from "ms_data";
import { IProps } from ".";
import { IInfo } from "./MyInfoMember";

const title = {
  nm: "이름",
  nick: "닉네임",
  orgEmail: "이메일",
};

export const getMemberProps = (
  props: IProps,
  key: "nm" | "nick" | "orgEmail"
): IInfo => {
  const { myInfoMemberStore, onUpdateHandler, focusStore } = props;
  const { values, validation, attributes } = myInfoMemberStore;

  const isEdit = focusStore.focus === key;

  const onEditHandler = () => {
    focusStore.setFocus(key);
    myInfoMemberStore.addRequired([key]);
  };

  const onCancelHandler = () => focusStore.setFocus(undefined);

  const onSubmitHandler: IInfo["onSubmitHandler"] = (e) => {
    e.preventDefault();
    if (isNil(values[key])) return;
    focusStore.setConfirm(true, `${title[key]}을 변경하시겠습니까?`, () => {
      onUpdateHandler(key);
      focusStore.setConfirm(false, "");
    });
  };

  /** 입력한 값이 유효하고, 기존 이메일과 같지 않을 때 수정 가능하다. */
  const isPossEdit =
    !isNil(values[key]) &&
    validation[key].isValid === true &&
    myInfoMemberStore.initialInfo[key] !== values[key];

  const input: IInfo["input"] = {
    ...attributes[key],
    type: "string",
    value: values[key] || "",
    invalid: validation[key].isValid === false,
    onChange: (e) => myInfoMemberStore.setForm(key, e.currentTarget.value),
    onBlur: (e) => myInfoMemberStore.setForm(key, e.currentTarget.value),
  };

  const inputMsg = {
    active: validation[key].isValid === false,
    msg: validation[key].errorMessage,
  };

  return {
    isEdit,
    onEditHandler,
    onCancelHandler,
    onSubmitHandler,
    isPossEdit,
    input,
    inputMsg,
  };
};
