import { observer } from "mobx-react";
import { MyInfoLogin } from "./MyInfoLogin";
import { PhoneProps } from "./Comp/Phone";
import { PasswordProps } from "./Comp/Password";
import { IMyInfoProps } from "../..";
import { AuthProps } from "./Comp/Auth";
import { useState } from "react";
import { PhoneStore } from "../../store/PhoneStore";
import { AuthFormStore } from "../../store/AuthStore";

export interface IMyInfoLoginProps extends IMyInfoProps {
  phoneStore: PhoneStore;
  authFormStore: AuthFormStore;
}

export const MyInfoLoginTpl = observer((props: IMyInfoProps) => {
  const { focusStore, authStore } = props;
  const [phoneStore] = useState(() => new PhoneStore(authStore.user!));
  const [authFormStore] = useState(() => new AuthFormStore());

  const obj = {
    ...props,
    phoneStore,
    authFormStore,
  };

  const phone = PhoneProps(obj);
  const auth = AuthProps(obj);
  const pw = PasswordProps(props);

  return (
    <>
      <MyInfoLogin
        phone={phone}
        auth={auth}
        pw={pw}
        onCancelHandler={(e) => focusStore.setFocus(undefined)}
      />
    </>
  );
});
