/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ReqPostSms  } from './interface/req/post/Req.Post.Sms';
import { ResPostSms  } from './interface/res/post/Res.Post.Sms';
import { ReqPostSmsConfirm  } from './interface/req/post/Req.Post.SmsConfirm';
import { ResPostSmsConfirm  } from './interface/res/post/Res.Post.SmsConfirm';
import { CommonForm  } from './interface/common/form/Common.Form';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Sms {

/**
 * 인증번호 보내기
*/
send_sms(data: ReqPostSms){
 return POST<ResPostSms>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/sms`,
 }, data);
}
/**
 * 문자메세지 인증. 가입, 비밀번호 변경 등에 사용
*/
confirm_sms(data: ReqPostSmsConfirm){
 return PUT<ResPostSmsConfirm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/sms`,
 }, data);
}
/**
 * 로그인한 유저가 인증번호 받을 경우에 사용
*/
send_sms_by_auth_token(data: ReqPostSms){
 return POST<ResPostSms>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/sms/auth`,
 }, data);
}
/**
 * 문자메세지 인증 후 조건이 맞을 경우 자동 가입.
*/
confirm_sms_auth(data: ReqPostSmsConfirm){
 return PUT<CommonForm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/sms/auth`,
 }, data);
}
/**
 * 인증번호 보내기 B2B
*/
send_sms_by_b2b(data: ReqPostSms){
 return POST<ResPostSms>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/sms/b2b`,
 }, data);
}
/**
 * 비밀번호 찾기
*/
find_sms_pw(data: ReqPostSms){
 return POST<ResPostSms>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/sms/findpw`,
 }, data);
}
 
}

export const SmsRepository = new Sms();
export const { send_sms, confirm_sms, send_sms_by_auth_token, confirm_sms_auth, send_sms_by_b2b, find_sms_pw } = SmsRepository;
	