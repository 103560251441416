export const OasPutEditInfo={
  "title": "Req.Put.EditInfo",
  "description": "정보 수정",
  "type": "object" as const,
  "properties": {
    "nm": {
      "type": "string" as const,
      "title": "이름",
      "pattern": "^[가-힣]{2,4}$",
      "minLength": 2,
      "maxLength": 4,
      "errorMessage": "이름은 2~4자의 한글로 입력해야 합니다."
    },
    "nick": {
      "type": "string" as const,
      "title": "닉네임",
      "pattern": "^[a-zA-Z0-9가-힣]{2,8}$",
      "minLength": 2,
      "maxLength": 8,
      "errorMessage": "닉네임은 2~8자의 영문자, 숫자 또는 한글로 입력해야 합니다."
    },
    "orgEmail": {
      "type": "string" as const,
      "title": "이메일",
      "pattern": "^[0-9a-zA-Z]([._-]?[0-9a-zA-Z])*@[a-zA-Z](?:[0-9a-zA-Z]+[._-])*[0-9a-zA-Z]+\\.[a-zA-Z]{2,}$",
      "errorMessage": "이메일 형식이 유효하지 않습니다."
    }
  }
}