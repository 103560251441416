export const OasPostSms={
  "title": "Req.Post.Sms",
  "description": "문자인증번호",
  "type": "object" as const,
  "properties": {
    "phone": {
      "title": "휴대폰 번호",
      "type": "string" as const,
      "pattern": "^010\\d{8}$",
      "minLength": 11,
      "maxLength": 11,
      "errorMessage": "휴대폰 번호는 “-”를 제외한 숫자만 입력해야 합니다."
    }
  },
  "required": [
    "phone"
  ] as const
}