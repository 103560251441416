/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { CommonForm  } from './interface/common/form/Common.Form';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Remove {

/**
 * 탈퇴가능한지 체크
*/
get_remove_check(){
 return GET<CommonForm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/remove`,
 });
}
/**
 * 탈퇴
*/
remove_user(){
 return DELETE<CommonForm>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/remove`,
 });
}
 
}

export const RemoveRepository = new Remove();
export const { get_remove_check, remove_user } = RemoveRepository;
	