import { PwStore } from "../../../store/PwStore";
import { IInputFormProps } from "../MyInfoLogin";

export const getPwProps = (
  props: { pwStore: PwStore },
  key: "pw" | "newPw" | "newPwC"
): IInputFormProps => {
  const { pwStore } = props;
  const { values, validation, attributes } = pwStore;

  return {
    input: {
      ...attributes[key],
      type: "password",
      onChange: (e) => pwStore.setForm(key, e.currentTarget.value),
      onBlur: (e) => pwStore.setForm(key, e.currentTarget.value),
      value: values[key] || "",
      invalid: validation[key].isValid === false,
      autocomplete: "off",
    },
    inputMsg: {
      active: validation[key].isValid === false,
      msg: validation[key].errorMessage,
    },
  };
};
