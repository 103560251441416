import { makeObservable } from "mobx";
import { AjvFormStore } from "ms-ui";
import { OasPostSmsConfirm } from "src/repository/Member/oas";

export class AuthFormStore extends AjvFormStore<typeof OasPostSmsConfirm> {
  constructor() {
    super(OasPostSmsConfirm);
    makeObservable(this, {});
  }
}
