export const OasPostSmsConfirm={
  "title": "Req.Post.SmsConfirm",
  "description": "문자 인증 확인",
  "type": "object" as const,
  "properties": {
    "phone": {
      "type": "string" as const,
      "title": "휴대폰 번호",
      "pattern": "^010\\d{8}$",
      "minLength": 11,
      "maxLength": 11,
      "errorMessage": "휴대폰 번호는 “-”를 제외한 숫자만 입력해야 합니다."
    },
    "authMsgId": {
      "type": "number" as const,
      "title": "인증번호 Key"
    },
    "authNo": {
      "type": "string" as const,
      "title": "인증번호",
      "pattern": "^[0-9]{4}$",
      "minLength": 4,
      "maxLength": 4,
      "errorMessage": "인증번호는 4자로 입력해야 합니다."
    },
    "clientId": {
      "type": "string" as const,
      "title": "GA4 clientId"
    }
  },
  "required": [
    "phone",
    "authMsgId",
    "authNo"
  ] as const
}