import { action, computed, makeObservable, observable, toJS } from "mobx";
import { IUser } from "ms_data";
import { OasPutEditInfo } from "src/repository/Member/oas";
import { edit_user_info } from "src/repository/Member/edit";
import { channelIO } from "src/func/tracking/channel";
import { myInfoMsg } from "src/constants/textTpl";
import { AjvFormStore, getErrorMsg } from "ms-ui";
import { AxiosResponse } from "axios";
import { CommonForm } from "src/repository/Member/interface/common/form/Common.Form";

interface IMyInfoMember {
  nm: string;
  nick: string;
  orgEmail: string;
}

export class MyInfoMemberStore extends AjvFormStore<typeof OasPutEditInfo> {
  _initialInfo: Partial<IUser>;

  constructor(user: Partial<IUser>) {
    super(OasPutEditInfo);

    makeObservable(this, {
      _initialInfo: observable,
      initialInfo: computed,
      setInitialInfo: action,

      submit: action,
    });

    this._initialInfo = user;
    this.initForm({
      nm: user.nm,
      nick: user.nick,
      orgEmail: user.orgEmail,
    });
  }

  get initialInfo() {
    return toJS(this._initialInfo);
  }

  setInitialInfo(key: keyof Partial<IUser>, value: any) {
    this._initialInfo[key] = value;
  }

  submit = async (key: keyof typeof this.values) => {
    try {
      const value = this.values[key];
      const res: AxiosResponse<CommonForm> = await edit_user_info({
        [`${key}`]: value,
      }).req();
      const { success } = res.data;

      if (success) {
        this._initialInfo[key] = value;

        if (key === "nick" || key === "nm") {
          channelIO.updateUser({ profile: { [key]: value! } });
        }

        return { success, msg: myInfoMsg[key].success };
      } else {
        return { success, msg: myInfoMsg[key].fail };
      }
    } catch (e) {
      return { success: false, msg: getErrorMsg(e) };
    }
  };
}
