import { useEffect, useState } from "react";
import { reaction } from "mobx";
import { isNil } from "ms_data";
import { edit_pw } from "src/repository/Member/edit";
import { getErrorMsg } from "ms-ui";
import { IMyInfoProps } from "../../..";
import { PwStore } from "../../../store/PwStore";
import { IPwProps } from "../MyInfoLogin";
import { getPwProps } from "./getPwProps";

export const PasswordProps = (props: IMyInfoProps): IPwProps => {
  const { focusStore } = props;
  const [pwStore] = useState(() => new PwStore());
  const { values, validation } = pwStore;

  useEffect(() => {
    reaction(
      () => focusStore.focus,
      (curr, prev) => {
        if (prev === "pw" && curr !== "pw") {
          pwStore.initForm();
        }
      }
    );
  }, []);

  const onSubmitHandler = async () => {
    try {
      focusStore.setLoading(true);
      const oldPassword = pwStore.values.pw!;
      const newPassword = pwStore.values.newPw!;

      const res = await edit_pw({
        pw: oldPassword,
        newPw: newPassword,
      }).req();

      const { success } = res.data;
      if (success) {
        focusStore.setFocus(undefined);
        focusStore.setAlert({
          msg: "비밀번호가 변경되었습니다.",
          open: true,
          fn: () => focusStore.setAlert({ open: false }),
        });
      }
    } catch (e) {
      //비번 틀림
      pwStore.setForm("pw", "");

      //alert
      focusStore.setAlert({
        msg: getErrorMsg(e),
        open: true,
        fn: () => {
          focusStore.setAlert({ open: false });
        },
      });
    } finally {
      focusStore.setLoading(false);
    }
  };

  const newPwC = getPwProps({ pwStore }, "newPwC");

  return {
    isEdit: focusStore.focus === "pw",
    onEditHandler: () => focusStore.setFocus("pw"),
    onSubmitHandler,
    isPossEdit: pwStore.isValid && values.newPw === values.newPwC,
    pw: getPwProps({ pwStore }, "pw"),
    newPw: getPwProps({ pwStore }, "newPw"),
    newPwC: {
      input: {
        ...newPwC.input,
        invalid:
          validation.newPwC.isValid === false ||
          (!isNil(values.newPwC) && values.newPw !== values.newPwC),
      },
      inputMsg: {
        active:
          validation.newPwC.isValid === false ||
          (!isNil(values.newPwC) && values.newPw !== values.newPwC),
        msg: isNil(values.newPwC)
          ? "새 비밀번호를 다시 입력하세요."
          : values.newPw !== values.newPwC
          ? "위 비밀번호와 다릅니다."
          : "",
      },
    },
  };
};
