export const OasPutEditPassword={
  "title": "Req.Put.EditPassword",
  "description": "비밀번호 변경",
  "type": "object" as const,
  "properties": {
    "pw": {
      "type": "string" as const,
      "title": "현재 비밀번호",
      "description": "기존 비밀번호",
      "pattern": "^.{7,20}$",
      "minLength": 7,
      "maxLength": 20,
      "errorMessage": "비밀번호는 7~20자로 입력해야 합니다."
    },
    "newPw": {
      "type": "string" as const,
      "title": "새 비밀번호",
      "description": "새롭게 바뀔 비밀번호",
      "pattern": "^.{7,20}$",
      "minLength": 7,
      "maxLength": 20,
      "errorMessage": "비밀번호는 7~20자로 입력해야 합니다."
    }
  },
  "required": [
    "pw",
    "newPw"
  ] as const
}