import { action, computed, makeObservable, observable, toJS } from "mobx";
import { AjvFormStore } from "ms-ui";
import { IUser } from "ms_data";
import { OasPostSms } from "src/repository/Member/oas";

export interface IMyInfoLogin {
  phone: string;
  authNo: string;
  authMsgId: number;
  pw: string;
  newPw: string;
  newPwC: string;
}

interface ITimer {
  text: string;
  on: boolean;
  retry: boolean;
}

type InitialInfo = Partial<IUser>;

export class MyInfoLoginStore extends AjvFormStore<typeof OasPostSms> {
  _initialInfo: InitialInfo;
  _timer: ITimer = {
    text: "02:00",
    on: false,
    retry: false,
  };

  constructor(user: Partial<IUser>) {
    super(OasPostSms);
    makeObservable(this, {
      _initialInfo: observable,
      initialInfo: computed,
      setInitialInfo: action,

      _timer: observable,
      timer: computed,
      setTimer: action,
    });

    this._initialInfo = user;
    this.initForm({ phone: user.phone });
  }

  get initialInfo() {
    return toJS(this._initialInfo);
  }

  setInitialInfo(key: keyof InitialInfo, value: any) {
    this._initialInfo[key] = value;
  }

  get timer() {
    return toJS(this._timer);
  }

  setTimer<T extends keyof typeof this._timer>(
    key: T,
    value: (typeof this._timer)[T]
  ) {
    this._timer[key] = value;
  }
}
