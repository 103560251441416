import { action, computed, flow, makeObservable, observable, toJS } from "mobx";
import { OasPostSms } from "src/repository/Member/oas";
import { IUser } from "ms_data";
import { send_sms_by_auth_token } from "src/repository/Member/sms";
import { AjvFormStore, getErrorMsg } from "ms-ui";

export interface IPhoneStore {
  phone: string;
}

interface ITimer {
  text: string;
  on: boolean;
  retry: boolean;
}

export class PhoneStore extends AjvFormStore<typeof OasPostSms> {
  _initialInfo: Partial<IUser>;
  _timer: ITimer = {
    text: "02:00",
    on: false,
    retry: false,
  };

  constructor(user: Partial<IUser>) {
    super(OasPostSms);
    makeObservable(this, {
      _initialInfo: observable,
      initialInfo: computed,
      setInitialInfo: action,

      _timer: observable,
      timer: computed,
      setTimer: action,

      submit: flow,
    });

    this._initialInfo = user;
  }

  get initialInfo() {
    return toJS(this._initialInfo);
  }

  setInitialInfo(key: keyof Partial<IUser>, value: any) {
    this._initialInfo[key] = value;
  }

  get timer() {
    return toJS(this._timer);
  }

  setTimer<T extends keyof typeof this._timer>(
    key: T,
    value: (typeof this._timer)[T]
  ) {
    this._timer[key] = value;
  }

  *submit() {
    try {
      const phone = this.values.phone;

      const res = yield send_sms_by_auth_token({ phone }).req();
      const { success, authMsgId } = res.data;

      if (success) {
        this.setTimer("text", "02:00");
        this.setTimer("on", true);
        this.setTimer("retry", false);
        return { success, authMsgId };
      } else {
        return { success: false };
      }
    } catch (e) {
      return { success: false, msg: getErrorMsg(e) };
    } finally {
    }
  }
}
