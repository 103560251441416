import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  Subtitle2,
  Body2,
  Tag,
  Input,
  InputMsg,
  InputCntn,
  Button,
} from "ms-ui";
import styled from "styled-components";

export interface IMyInfoMember {
  /**
   * 이름
   */
  nm: IInfo;

  /**
   * 닉네임
   */
  nick: IInfo;

  /**
   * 이메일
   */
  orgEmail: IInfo;
}

export interface IInfo {
  /**
   * 수정모드인지 여부
   */
  isEdit: boolean;

  /**
   * 수정 버튼 클릭 이벤트
   */
  onEditHandler: React.MouseEventHandler<HTMLDivElement>;

  /**
   * 취소 버튼 클릭 이벤트
   */
  onCancelHandler: React.MouseEventHandler<HTMLDivElement>;

  /**
   * 변경 버튼 클릭 이벤트
   */
  onSubmitHandler: React.MouseEventHandler<HTMLDivElement>;

  /**
   * 변경 버튼 활성화 여부
   */
  isPossEdit: boolean;
  input: IInputProps;
  inputMsg: IInputMsgProps;
}

export interface IInputProps {
  value: string;
  invalid: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type: string;
  inputMode?: any;
  onBlur: React.ChangeEventHandler<HTMLInputElement>;
}

export interface IInputMsgProps {
  active: boolean;
  msg: string;
}

export const MyInfoMember = observer(
  ({ nm, nick, orgEmail }: IMyInfoMember) => {
    return (
      <>
        <StDiv1>
          <StDiv2>회원 정보</StDiv2>
          <StDiv3>
            <table>
              <tbody>
                <StDiv4>
                  <StDiv5>
                    <StDiv7>이름</StDiv7>
                  </StDiv5>
                  <StDiv6>
                    {nm.isEdit === false && (
                      <StDiv8>
                        <StDiv9>{nm.input.value || "-"}</StDiv9>
                        <Tag
                          color={`black`}
                          radius={`5`}
                          light={false}
                          onClick={nm.onEditHandler}
                          style={{ cursor: "pointer" }}
                          msg={`수정`}
                          width={`fit-content`}
                        />
                      </StDiv8>
                    )}
                    {nm.isEdit === true && (
                      <StDiv10>
                        <StDiv11>
                          <InputCntn bottom={`0px`}>
                            <Input
                              placeholder={`이름을 입력하세요.`}
                              size={`sm`}
                              {...nm.input}
                            />
                            <InputMsg color={`negative`} {...nm.inputMsg} />
                          </InputCntn>
                          <StDiv12>
                            <Button
                              color={`black`}
                              size={`sm`}
                              radius={`5`}
                              outline={true}
                              onClick={nm.onCancelHandler}
                              width={`100%`}
                            >
                              취소
                            </Button>
                            <Button
                              color={`primary`}
                              size={`sm`}
                              radius={`5`}
                              outline={false}
                              disabled={!nm.isPossEdit}
                              onClick={nm.onSubmitHandler}
                              width={`100%`}
                            >
                              변경
                            </Button>
                          </StDiv12>
                        </StDiv11>
                      </StDiv10>
                    )}
                  </StDiv6>
                </StDiv4>
                <StDiv4>
                  <StDiv5>
                    <StDiv13>닉네임</StDiv13>
                  </StDiv5>
                  <StDiv6>
                    {nick.isEdit === false && (
                      <StDiv14>
                        <StDiv15>{nick.input.value}</StDiv15>
                        <Tag
                          color={`black`}
                          radius={`5`}
                          light={false}
                          onClick={nick.onEditHandler}
                          style={{ cursor: "pointer" }}
                          msg={`수정`}
                          width={`fit-content`}
                        />
                      </StDiv14>
                    )}
                    {nick.isEdit === true && (
                      <StDiv16>
                        <StDiv17>
                          <StDiv18>
                            <InputCntn bottom={`0px`}>
                              <Input
                                placeholder={`닉네임을 입력하세요.`}
                                size={`sm`}
                                {...nick.input}
                              />
                              <InputMsg color={`negative`} {...nick.inputMsg} />
                            </InputCntn>
                            <StDiv19>
                              <Button
                                color={`black`}
                                size={`sm`}
                                radius={`5`}
                                outline={true}
                                onClick={nick.onCancelHandler}
                                width={`100%`}
                              >
                                취소
                              </Button>
                              <Button
                                color={`primary`}
                                size={`sm`}
                                radius={`5`}
                                outline={false}
                                disabled={!nick.isPossEdit}
                                onClick={nick.onSubmitHandler}
                                width={`100%`}
                              >
                                변경
                              </Button>
                            </StDiv19>
                          </StDiv18>
                        </StDiv17>
                      </StDiv16>
                    )}
                  </StDiv6>
                </StDiv4>
                <StDiv4>
                  <StDiv5>
                    <StDiv20>이메일</StDiv20>
                  </StDiv5>
                  <StDiv6>
                    {orgEmail.isEdit === false && (
                      <StDiv21>
                        <StDiv22>{orgEmail.input.value || "-"}</StDiv22>
                        <Tag
                          color={`black`}
                          radius={`5`}
                          light={false}
                          onClick={orgEmail.onEditHandler}
                          style={{ cursor: "pointer" }}
                          msg={`수정`}
                          width={`fit-content`}
                        />
                      </StDiv21>
                    )}
                    {orgEmail.isEdit === true && (
                      <StDiv23>
                        <StDiv24>
                          <InputCntn bottom={`0px`}>
                            <Input
                              placeholder={`이메일을 입력하세요.`}
                              size={`sm`}
                              {...orgEmail.input}
                            />
                            <InputMsg
                              color={`negative`}
                              {...orgEmail.inputMsg}
                            />
                          </InputCntn>
                          <StDiv25>
                            <Button
                              color={`black`}
                              size={`sm`}
                              radius={`5`}
                              outline={true}
                              onClick={orgEmail.onCancelHandler}
                              width={`100%`}
                            >
                              취소
                            </Button>
                            <Button
                              color={`primary`}
                              size={`sm`}
                              radius={`5`}
                              outline={false}
                              disabled={!orgEmail.isPossEdit}
                              onClick={orgEmail.onSubmitHandler}
                              width={`100%`}
                            >
                              변경
                            </Button>
                          </StDiv25>
                        </StDiv24>
                      </StDiv23>
                    )}
                  </StDiv6>
                </StDiv4>
              </tbody>
            </table>
          </StDiv3>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[24].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${Subtitle2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv4 = styled.tr`
  display: flex;
  width: 100%;
`;

const StDiv5 = styled.th`
  display: flex;
  width: 90px;
  padding: ${spacing[4].value} 0px;
  align-items: center;
  text-align: left;
`;

const StDiv6 = styled.td`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv7 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.accent.value};
`;

const StDiv8 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv9 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv10 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv11 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};
  flex: 1 0 0;
  width: 100%;
`;

const StDiv12 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;

const StDiv13 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.accent.value};
`;

const StDiv14 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv15 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv16 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv17 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv18 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};
  flex: 1 0 0;
  width: 100%;
`;

const StDiv19 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;

const StDiv20 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.accent.value};
`;

const StDiv21 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv22 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv23 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv24 = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[8].value};
  flex: 1 0 0;
  width: 100%;
`;

const StDiv25 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;
