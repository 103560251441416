import { observer } from "mobx-react";
import { MyInfoMember } from "./MyInfoMember";
import { IMyInfoProps } from "../..";
import { getMemberProps } from "./getMemberProps";

export interface IProps extends IMyInfoProps {
  onUpdateHandler: (key: "nm" | "nick" | "orgEmail") => Promise<void>;
}

export const MyInfoMemberTpl = observer((props: IMyInfoProps) => {
  const { myInfoMemberStore, authStore, focusStore } = props;

  const onUpdateHandler = async (key: "nm" | "nick" | "orgEmail") => {
    focusStore.setLoading(true);

    const value = myInfoMemberStore.values[key];
    const { success, msg } = await myInfoMemberStore.submit(key);
    if (success) {
      authStore.setUser({
        ...authStore.user,
        [`${key}`]: value,
      });

      focusStore.setFocus(undefined);
    }

    focusStore.setAlert({
      msg,
      open: true,
      fn: () => focusStore.setAlert({ open: false }),
    });

    focusStore.setLoading(false);
  };

  const obj = {
    ...props,
    onUpdateHandler,
  };

  const nm = getMemberProps(obj, "nm");
  const nick = getMemberProps(obj, "nick");
  const orgEmail = getMemberProps(obj, "orgEmail");

  return <MyInfoMember nm={nm} nick={nick} orgEmail={orgEmail} />;
});
