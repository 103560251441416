import React from "react";
import { observer } from "mobx-react";
import {
  spacing,
  contents,
  Subtitle2,
  Body2,
  Tag,
  InputMsg,
  Input,
  Button,
  InputCntn,
  InputBox,
  Body3,
} from "ms-ui";
import styled from "styled-components";

export interface IMyInfoLogin {
  phone: IPhoneProps;
  auth: IAuthProps;
  pw: IPwProps;
  onCancelHandler: React.MouseEventHandler<HTMLDivElement>;
}

export interface IPhoneProps {
  /**
   * 휴대폰 번호 수정 버튼 클릭 이벤트
   */
  onEditHandler: React.MouseEventHandler<HTMLDivElement>;

  /**
   * 전송버튼 활성화 여부
   */
  isPossRequest: boolean;

  /**
   * 휴대폰 번호 수정모드인지 여부
   */
  isEdit: boolean;

  /**
   * 전송 버튼 클릭 이벤트
   */
  onSubmitHandler: React.MouseEventHandler<HTMLDivElement>;
  input: IInputFormProps["input"];
  inputMsg: IInputFormProps["inputMsg"];
  initialValue: string;
}

export interface IAuthProps {
  /**
   * 인증번호 유효 시간
   */
  timer: React.ReactNode;

  /**
   * 인증버튼 활성화 여부
   */
  isPossConfirm: boolean;

  /**
   * 인증번호 form 노출 여부
   */
  isVisible: boolean;
  onSubmitHandler: React.MouseEventHandler<HTMLDivElement>;
  input: IInputFormProps["input"];
  inputMsg: IInputFormProps["inputMsg"];
}

export interface IPwProps {
  /**
   * 비밀번호 수정모드인지 여부
   */
  isEdit: boolean;

  /**
   * 비밀번호 수정 버튼 클릭 이벤트
   */
  onEditHandler: React.MouseEventHandler<HTMLDivElement>;

  /**
   * 변경 버튼 클릭 이벤트
   */
  onSubmitHandler: React.MouseEventHandler<HTMLDivElement>;

  /**
   * 변경 버튼 활성화 여부
   */
  isPossEdit: boolean;

  /**
   * 현재 비밀번호
   */
  pw: IInputFormProps;

  /**
   * 새 비밀번호
   */
  newPw: IInputFormProps;

  /**
   * 새 비밀번호 확인
   */
  newPwC: IInputFormProps;
}

export interface IInputProps {
  value: string;
  invalid: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  type: string;
  inputMode?: any;
  children?: React.ReactNode;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  autocomplete?: "on" | "off";
}

export interface IInputMsgProps {
  active: boolean;
  msg: string;
}

export interface IInputFormProps {
  input: IInputProps;
  inputMsg: IInputMsgProps;
}

export const MyInfoLogin = observer(
  ({ phone, auth, pw, onCancelHandler }: IMyInfoLogin) => {
    return (
      <>
        <StDiv1>
          <StDiv2>로그인 정보</StDiv2>
          <StDiv3>
            <StDiv4>
              <StDiv5>
                <StDiv6>휴대폰 번호</StDiv6>
              </StDiv5>
              <StDiv7>
                {phone.isEdit === false && (
                  <StDiv8>
                    <StDiv9>{phone.initialValue}</StDiv9>
                    <Tag
                      color={`black`}
                      radius={`5`}
                      light={false}
                      onClick={phone.onEditHandler}
                      style={{ cursor: "pointer" }}
                      msg={`수정`}
                      width={`fit-content`}
                    />
                  </StDiv8>
                )}
                {phone.isEdit === true && (
                  <StDiv10>
                    <StDiv11>
                      <InputCntn bottom={`0px`}>
                        <StDiv12>
                          <Input
                            // value={내용을 입력해주세요.}
                            placeholder={`휴대폰 번호를 입력하세요.`}
                            size={`sm`}
                            {...phone.input}
                          />
                          <Button
                            color={`primary`}
                            size={`sm`}
                            radius={`5`}
                            outline={false}
                            onClick={phone.onSubmitHandler}
                            disabled={!phone.isPossRequest}
                            width={`fit-content`}
                          >
                            전송
                          </Button>
                        </StDiv12>
                        <InputMsg
                          active={phone.inputMsg.active}
                          color={`negative`}
                          msg={phone.inputMsg.msg}
                        />
                      </InputCntn>
                      {auth.isVisible === true && (
                        <InputCntn bottom={`0px`}>
                          <InputBox
                            // value={내용을 입력해주세요.}
                            placeholder={`인증번호를 입력하세요.`}
                            size={`sm`}
                            {...auth.input}
                          >
                            {auth.timer}
                          </InputBox>
                          <InputMsg
                            active={auth.inputMsg.active}
                            color={`negative`}
                            msg={auth.inputMsg.msg}
                          />
                        </InputCntn>
                      )}
                      <StDiv13>
                        <Button
                          color={`black`}
                          size={`sm`}
                          radius={`5`}
                          outline={true}
                          onClick={onCancelHandler}
                          width={`100%`}
                        >
                          취소
                        </Button>
                        <Button
                          color={`primary`}
                          size={`sm`}
                          radius={`5`}
                          outline={false}
                          onClick={auth.onSubmitHandler}
                          disabled={!auth.isPossConfirm}
                          width={`100%`}
                        >
                          인증
                        </Button>
                      </StDiv13>
                    </StDiv11>
                  </StDiv10>
                )}
              </StDiv7>
            </StDiv4>
            <StDiv14>
              <StDiv15>
                <StDiv16>비밀번호</StDiv16>
              </StDiv15>
              <StDiv17>
                {pw.isEdit === false && (
                  <StDiv18>
                    <StDiv19>●●●●●●●●</StDiv19>
                    <Tag
                      color={`black`}
                      radius={`5`}
                      light={false}
                      onClick={pw.onEditHandler}
                      style={{ cursor: "pointer" }}
                      msg={`수정`}
                      width={`fit-content`}
                    />
                  </StDiv18>
                )}
                {pw.isEdit === true && (
                  <StDiv20>
                    <InputCntn bottom={`8px`}>
                      <Input
                        placeholder={`현재 비밀번호를 입력하세요.`}
                        size={`sm`}
                        {...pw.pw.input}
                      />
                      <InputMsg
                        active={pw.pw.inputMsg.active}
                        color={`negative`}
                        msg={pw.pw.inputMsg.msg}
                      />
                    </InputCntn>
                    <InputCntn bottom={`8px`}>
                      <Input
                        placeholder={`새 비밀번호를 입력하세요.`}
                        size={`sm`}
                        {...pw.newPw.input}
                      />
                      <InputMsg
                        active={pw.newPw.inputMsg.active}
                        color={`negative`}
                        msg={pw.newPw.inputMsg.msg}
                      />
                    </InputCntn>
                    <Input
                      placeholder={`새 비밀번호를 다시 입력하세요.`}
                      size={`sm`}
                      {...pw.newPwC.input}
                    />
                    <InputCntn bottom={`8px`}>
                      <InputMsg
                        active={pw.newPwC.inputMsg.active}
                        color={`negative`}
                        msg={pw.newPwC.inputMsg.msg}
                      />
                    </InputCntn>
                    <StDiv21>
                      <Button
                        color={`black`}
                        size={`sm`}
                        radius={`5`}
                        outline={true}
                        onClick={onCancelHandler}
                        width={`100%`}
                      >
                        취소
                      </Button>
                      <Button
                        color={`primary`}
                        size={`sm`}
                        radius={`5`}
                        outline={false}
                        onClick={pw.onSubmitHandler}
                        disabled={!pw.isPossEdit}
                        width={`100%`}
                      >
                        변경
                      </Button>
                    </StDiv21>
                  </StDiv20>
                )}
              </StDiv17>
            </StDiv14>
          </StDiv3>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[16].value};
  flex-direction: column;
  gap: ${spacing[24].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${Subtitle2};
  color: ${contents.primary.value};
  width: fit-content;
`;

const StDiv3 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StDiv4 = styled.div`
  display: flex;
  width: 100%;
`;

const StDiv5 = styled.div`
  display: flex;
  width: 90px;
  padding: ${spacing[4].value} 0px;
  align-items: center;
`;

const StDiv6 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.accent.value};
`;

const StDiv7 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv8 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv9 = styled.div`
  ${Body2};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv10 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv11 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${spacing[8].value};
  flex: 1 0 0;
  width: 100%;
`;

const StDiv12 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;

const StDiv13 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;

const StDiv14 = styled.div`
  display: flex;
  width: 100%;
`;

const StDiv15 = styled.div`
  display: flex;
  width: 90px;
  padding: ${spacing[4].value} 0px;
  align-items: center;
`;

const StDiv16 = styled.div`
  ${Body2};
  flex: 1 0 0;
  color: ${contents.accent.value};
`;

const StDiv17 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv18 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StDiv19 = styled.div`
  ${Body3};
  color: ${contents.primary.value};
  text-align: right;
  width: fit-content;
`;

const StDiv20 = styled.div`
  display: flex;
  padding: ${spacing[4].value} 0px;
  flex-direction: column;
  width: 100%;
`;

const StDiv21 = styled.div`
  display: flex;
  gap: ${spacing[8].value};
  width: 100%;
`;
