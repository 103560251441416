/**
 * 워커로 만드려는 함수를 받아 blob을 사용해 워커로
 */
export default class WebWorker {
  public worker: Worker;
  constructor(worker: Function) {
    let code = worker.toString();
    code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));
    const blob = new Blob([code], { type: "application/javascript" });
    this.worker = new Worker(URL.createObjectURL(blob));
  }
}
